import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// angular material imports
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';

// custom components
import { Uxc1001Component } from './UX-C1/001/uxc1001.component';
import { Uxc1002Component } from './UX-C1/002/uxc1002.component';
import { Uxc1003Component } from './UX-C1/003/uxc1003.component';
import { Uxc1004Component } from './UX-C1/004/uxc1004.component';
import { Uxc1004bComponent } from './UX-C1/004b/uxc1004b.component';
import {
  Uxc1004cComponent,
  Uxc1004cDialogComponent
} from './UX-C1/004c/uxc1004c.component';
import {
  Uxc1004dComponent,
  Uxc1004dDialogComponent
} from './UX-C1/004d/uxc1004d.component';
import { Uxc1004eComponent } from './UX-C1/004e/uxc1004e.component';
import { Uxc1004fComponent } from './UX-C1/004f/uxc1004f.component';
import {
  Uxc1005Component,
  Uxc1005BottomSheetComponent
} from './UX-C1/005/uxc1005.component';
import { Uxc1004gComponent } from './UX-C1/004g/uxc1004g.component';
import { Uxc13001Component } from './UX-C13/001/uxc13001.component';
import { Uxc1001bComponent } from './UX-C1/001b/uxc1001b.component';
import { Uxc1004dConfirmComponent } from './UX-C1/004d-confirm/uxc1004d-confirm.component';

// google maps
import { AgmCoreModule } from '@agm/core';
import { Uxc2001Component } from './UX-C2/001/uxc2001.component';
import { Uxc3001Component } from './UX-C3/001/uxc3001.component';
import { Uxc3002Component } from './UX-C3/002/uxc3002.component';
import { Uxc3003Component } from './UX-C3/003/uxc3003.component';

import { Uxc4001Component } from './UX-C4/001/uxc4001.component';
import { Uxc4002Component } from './UX-C4/002/uxc4002.component';
import { Uxc4003Component } from './UX-C4/003/uxc4003.component';

import { Uxc5001Component } from './UX-C5/001/uxc5001.component';
import { Uxc5003Component } from './UX-C5/003/uxc5003.component';
import { Uxc5004Component } from './UX-C5/004/uxc5004.component';
import { Uxc5005Component } from './UX-C5/005/uxc5005.component';
import { Uxc5006Component } from './UX-C5/006/uxc5006.component';
import { Uxc5007Component } from './UX-C5/007/uxc5007.component';
import { Uxc5008Component } from './UX-C5/008/uxc5008.component';
import { Uxc5009Component } from './UX-C5/009/uxc5009.component';

import { Uxc7001Component } from './UX-C7/001/uxc7001.component';
import { Uxc7003Component } from './UX-C7/003/uxc7003.component';

import { Uxc8001Component } from './UX-C8/001/uxc8001.component';
import { Uxc8003Component } from './UX-C8/003/uxc8003.component';
import { Uxc8004Component } from './UX-C8/004/uxc8004.component';
import { Uxc8005Component } from './UX-C8/005/uxc8005.component';

import { Uxc9001Component } from './UX-C9/001/uxc9001.component';
import { Uxc9002Component } from './UX-C9/002/uxc9002.component';
import { Uxc9003Component } from './UX-C9/003/uxc9003.component';
import { Uxc9004Component } from './UX-C9/004/uxc9004.component';

import { Uxc10001Component } from './UX-C10/001/uxc10001.component';
import { Uxc10002Component } from './UX-C10/002/uxc10002.component';

import { Uxc1101Component } from './UX-C11/001/uxc1101.component';

import { Uxc1201Component } from './UX-C12/001/uxc1201.component';

import { AuthGuard } from './services/auth-guard.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { HeaderComponent } from './Shared/header/header.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { AddressPickerComponent } from './Shared/address-picker/address-picker.component';
import { ItemPickerComponent } from './Shared/item-picker/item-picker.component';
import { DatePickerComponent } from './Shared/date-picker/date-picker.component';
import {
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { CharityPanelComponent } from './Shared/charity-panel/charity-panel.component';
import { DisplayCharityComponent } from './Shared/display-charity/display-charity.component';
import { ItemAndNumberPickerComponent } from './Shared/item-and-number-picker/item-and-number-picker.component';
import { AlertComponent } from './Shared/alert/alert.component';

// Flex layout
import { FlexLayoutModule } from '@angular/flex-layout';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PrivacyPolicyComponent } from './Shared/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './Shared/terms-and-conditions/terms-and-conditions.component';
import { Router, RouterModule } from '@angular/router';
import { NoBackHeaderComponent } from './Shared/no-back-header/no-back-header.component';

@NgModule({
  declarations: [
    AppComponent,
    Uxc1001Component,
    Uxc1002Component,
    Uxc1003Component,
    Uxc1004Component,
    Uxc1004bComponent,
    Uxc1005Component,
    Uxc1005BottomSheetComponent,
    Uxc2001Component,
    Uxc3001Component,
    Uxc4001Component,
    Uxc3002Component,
    Uxc3003Component,
    Uxc4002Component,
    Uxc4003Component,
    Uxc5001Component,
    AddressPickerComponent,
    ItemPickerComponent,
    Uxc7001Component,
    HeaderComponent,
    NoBackHeaderComponent,
    FooterComponent,
    AlertComponent,
    Uxc5003Component,
    DatePickerComponent,
    Uxc5004Component,
    Uxc5005Component,
    Uxc5006Component,
    Uxc5007Component,
    Uxc5008Component,
    Uxc5009Component,
    Uxc1004cComponent,
    Uxc1004cDialogComponent,
    Uxc1004dComponent,
    Uxc1004dDialogComponent,
    CharityPanelComponent,
    Uxc1004eComponent,
    Uxc1004fComponent,
    DisplayCharityComponent,
    Uxc7003Component,
    Uxc8001Component,
    ItemAndNumberPickerComponent,
    Uxc8003Component,
    Uxc8004Component,
    Uxc8005Component,
    Uxc9001Component,
    Uxc9002Component,
    Uxc9003Component,
    Uxc9004Component,
    Uxc10001Component,
    Uxc10002Component,
    Uxc1101Component,
    Uxc1201Component,
    Uxc1004gComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    Uxc13001Component,
    Uxc1001bComponent,
    Uxc1004dConfirmComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    MatSliderModule,
    MatInputModule,
    MatRadioModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatListModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatRippleModule,
    MatDialogModule,
    MatSelectModule,
    MatBottomSheetModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    FlexLayoutModule,
    SlickCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAGJ_TGzKtLrN9ceEI0YoF01wxtFmip9EI',
      libraries: ['places']
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    RouterModule
  ],
  providers: [
      AuthGuard,
    {
      provide: MAT_DATE_LOCALE, useValue: 'en-gb'
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
    ],
  bootstrap: [AppComponent]
})
export class AppModule {}
export class ThemeAppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('personal-dark-theme');
    overlayContainer.getContainerElement().classList.add('charity-theme');
    overlayContainer.getContainerElement().classList.add('charity-dark-theme');
  }
}

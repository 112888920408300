import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { IAddress, IOptionType } from 'src/app/helper/appInterfaces';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { AppService } from 'src/app/services/app.service';
import { appUtils } from 'src/app/helper/appUtils';
import { appParam } from 'src/app/helper/appSettings';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-uxc5003',
  templateUrl: './uxc5003.component.html',
  styleUrls: ['./uxc5003.component.scss']
})
export class Uxc5003Component implements OnInit {
  _style: string = '';
  _customerNotes: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;
  primaryButtonDisabled: boolean = true;

  availableDates: any[] = [];

  selectedDate: string = '';
  selectedDay: string = '';
  containerType: IOptionType = { key: '', description: '' };
  subscriptionType: IOptionType = {
    key: '',
    description: '',
    bin_fee: undefined
  };
  orderType: IOptionType = { key: '', description: '' };
  charityType: IOptionType = { key: '', description: '' };
  resCustomerAddress: any;
  resCharity: any;
  taxInvoiceEmail: string = '';
  customerType: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5003;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    Sentry.addBreadcrumb({
      level: 'log',
      message: 'UX-C5003 - Request a bin'
    })

    this.appService.appSpinner(true);
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
    this.updatePrimaryButtonStatus();
  }

  async getData() {
    let resCustomer = await this.appApi.getCustomerByEmail(
      this.authService.getCurrentUserId()
    );
    this.customerType = resCustomer.customerType;

    if (this.customerType == appParam.userType.charity) {
      this.progressValue = 50;
    } else {
      this.progressValue = this.workflowStep.progressValue();
    }

    // get addresses from the backend
    this.resCustomerAddress = await this.appApi.getCustomerAddresses();
    this._customerNotes = this.resCustomerAddress[0].notes;

    // get available dates based on postcode
    let res = await this.appApi.getAvailableDates(
      this.resCustomerAddress[0].refSuburb.id,
      true
    );

    Sentry.captureMessage(`User ID: ${this.authService.getCurrentUserId()} called getAvailableDates with following params - 
    Suburb ID: ${this.resCustomerAddress[0].refSuburb.id}, first drop: true`, 'debug')

    console.log(res)

    // 2021-10-21 CJ - Fix - only future dates are available for pickup
    const dtNow = new Date();
    this.availableDates = res.filter((item) => item > dtNow);

    // logic for when user hits back button to preserve values selected previously
    const workflowData = await this.appService.getOrderSummary();
    if (
      workflowData.selected_date.key != undefined &&
      workflowData.selected_date.key != ''
    ) {
      await this.updateSelectedDate(workflowData.selected_date.key);
    }

    if (this.customerType == appParam.userType.charity) {
      this.taxInvoiceEmail = this.appService.UserObject.taxInvoiceEmail;

      // get customer charity details and store in workflow object
      let resCharity = await this.appApi.getCharities(
        this.appService.UserObject.charityId
      );
      if (resCharity.length > 0) {
        this.charityType.key = resCharity[0].key;
        this.charityType.description = resCharity[0].description;
        this.resCharity = resCharity[0];
      }

      let resSubscriptionType = await this.appApi.getSubscriptionTypes(
        appParam.subscriptionCategory.charity
      );
      if (resSubscriptionType.length > 0) {
        this.subscriptionType.key = resSubscriptionType[0].key;
        this.subscriptionType.description = resSubscriptionType[0].description;
      }
    }

    let resSubscriptionType = await this.appApi.getSubscriptionTypes(
      appParam.subscriptionCategory.basic
    );
    if (resSubscriptionType.length > 0) {
      this.subscriptionType.key = resSubscriptionType[0].key;
      this.subscriptionType.description = resSubscriptionType[0].description;
    }

    let resContainerType = await this.appApi.getContainerTypes(
      appParam.containerCategory.bin
    );
    if (resContainerType.length > 0) {
      this.containerType.key = resContainerType[0].key;
      this.containerType.description = resContainerType[0].description;
    }

    // get order type details and store in workflow object
    let resOrderType = await this.appApi.getOrderTypes(appParam.orderType.drop);
    if (resOrderType.length > 0) {
      this.orderType.key = resOrderType[0].key;
      this.orderType.description = resOrderType[0].description;
    }

    this.appService.appSpinner(false);
  }

  async datePicked(event: any) {
    await this.updateSelectedDate(event);
  }

  async updateSelectedDate(_date: any) {
    this.selectedDate = _date;
    this.selectedDay = ' ' + appUtils.getDayofWeek(this.selectedDate);
    this.updatePrimaryButtonStatus();
  }

  async next() {
    this.appService.appSpinner(true);
    await this.save();

    if (this.customerType == appParam.userType.charity) {
      this.router.navigate(['/' + this.workflowStep.next.charity], {
        relativeTo: this.route
      });
    } else if (this.customerType == appParam.userType.basic) {
      this.router.navigate(['/' + this.workflowStep.next.basic], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(['/' + this.workflowStep.next.premium], {
        relativeTo: this.route
      });
    }
  }

  async updatePrimaryButtonStatus() {
    this.primaryButtonDisabled =
      this.selectedDate == '' || this.selectedDate == null;

    let btn: HTMLElement;
    btn = document.getElementById('primaryButton') as HTMLElement;

    if (this.primaryButtonDisabled == true) {
      btn.className = btn.className + ' byc-bottom-disabled';
    } else {
      btn.className = btn.className.replace('byc-bottom-disabled', '');
    }
  }

  async save() {
    if (this.customerType == appParam.userType.charity) {
      this.appService.updateWorkflowData({
        workflowId: this.workflowStep.id,
        workflowData: {
          address: {
            key: this.resCustomerAddress[0].key,
            street_address: this.resCustomerAddress[0].street_address,
            suburb: this.resCustomerAddress[0].refSuburb,
            lat: this.resCustomerAddress[0].lat,
            lng: this.resCustomerAddress[0].lng
          },
          selected_date: {
            key: this.selectedDate,
            description: this.selectedDate
          },
          container_type: {
            key: this.containerType.key,
            description: this.containerType.description
          },
          subscription_type: {
            key: this.subscriptionType.key,
            description: this.subscriptionType.description
          },
          order_type: {
            key: this.orderType.key,
            description: this.orderType.description
          },
          taxInvoiceEmail: this.taxInvoiceEmail,
          charity: {
            key: this.charityType.key,
            description: this.charityType.description,
            charityObject: this.resCharity
          },
          notes: this._customerNotes
        }
      });
      if (this._customerNotes !== '') {
        await this.appApi.updateCustomerNotes(this._customerNotes);
      }
    } else if (this.customerType == appParam.userType.basic) {
      this.appService.updateWorkflowData({
        workflowId: this.workflowStep.id,
        workflowData: {
          address: {
            key: this.resCustomerAddress[0].key,
            street_address: this.resCustomerAddress[0].street_address,
            suburb: this.resCustomerAddress[0].refSuburb,
            lat: this.resCustomerAddress[0].lat,
            lng: this.resCustomerAddress[0].lng
          },
          selected_date: {
            key: this.selectedDate,
            description: this.selectedDate
          },
          subscription_type: {
            key: this.subscriptionType.key,
            description: this.subscriptionType.description
          },
          container_type: {
            key: this.containerType.key,
            description: this.containerType.description
          },
          order_type: {
            key: this.orderType.key,
            description: this.orderType.description
          },
          notes: this._customerNotes
        }
      });
      if (this._customerNotes !== '') {
        await this.appApi.updateCustomerNotes(this._customerNotes);
      }
    } else {
      this.appService.updateWorkflowData({
        workflowId: this.workflowStep.id,
        workflowData: {
          address: {
            key: this.resCustomerAddress[0].key,
            street_address: this.resCustomerAddress[0].street_address,
            suburb: this.resCustomerAddress[0].refSuburb,
            lat: this.resCustomerAddress[0].lat,
            lng: this.resCustomerAddress[0].lng
          },
          selected_date: {
            key: this.selectedDate,
            description: this.selectedDate
          },
          container_type: {
            key: this.containerType.key,
            description: this.containerType.description
          },
          order_type: {
            key: this.orderType.key,
            description: this.orderType.description
          },
          notes: this._customerNotes
        }
      });
      if (this._customerNotes !== '') {
        await this.appApi.updateCustomerNotes(this._customerNotes);
      }
    }
  }
}
